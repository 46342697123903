/** @format */

.accountant-dashboard {
  #due-date-period {
    margin-bottom: 1rem;
  }

  .status-tile-container {
    margin: 2rem 0;
  }

  .overall-status-tile {
    display: inline-block;
    border: 1px solid #ddd;
    background-color: white;
    padding: 0.5rem 1rem;
    margin-right: 1rem;
    border-radius: 0.25rem;
    min-width: 12rem;

    h5 {
      font-size: 1rem;
    }
  }

  .operator-grid-header {
    border-bottom: 1px solid #ddd;
    margin-top: 1rem;

    [class*="col"] {
      padding-bottom: 0.5rem;

      &:not(:last-of-type) {
        border-right: 1px solid #ddd;
      }
    }

    .col:last-of-type {
      border-right: none;
    }
  }

  .operator-submission-stats {
    background-color: white;
    border: 1px solid #ddd;

    .header-row {
      cursor: pointer;
      border-bottom: 1px solid #ddd;

      &:hover {
        background-color: #eee;
      }

      [class*="col"] {
        padding: 1rem;

        &:not(:last-of-type) {
          border-right: 1px solid #ddd;
        }
      }
    }
    .requirement-stats {
      padding: 0.5rem 1rem;
      font-size: 0.9rem;

      .requirement-header-row {
        margin-bottom: 0.25rem;
        padding: 1rem;

        [class*="col"] {
          border-bottom: 1px solid #ddd;
        }
      }
    }

    .requirement-row {
      position: relative;
      padding: 0.25rem 1rem;

      /*&:before {
        content: "";
        width: 0.75rem;
        height: 0.75rem;
        border-radius: 0.75rem;
        background: #ddd;
        margin-top: 0.35rem;
        position: absolute;
        left: 0.75rem;
      }*/
    }
  }
}
