/** @format */

.wtop-settings {
  h3 {
    margin-bottom: 1rem;
  }

  .settings-section {
    margin-bottom: 3rem;
  }

  .settings-field {
    [class*=" col-"] {
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
    }
  }
}
