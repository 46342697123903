/** @format */
@import "../../variables.scss";
/* TODO are we still using this after removing favorites from OperatorPickerClassic? */
.favorite-toggle {
  display: inline-block;
  position: relative;
  padding: 8px 12px;

  &:hover::after {
    content: "";
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    top: 8px; // from 8px padding
    left: 9px; // -3px from 12px padding
    border: 1px solid var(--warning);
  }

  &.favorite {
    .fa-star {
      color: var(--warning);
    }
  }

  &.non-favorite {
    .fa-star {
      color: var(--gray);
    }
  }
}
.document-type-picker-classic {
  .favorite-toggle {
    padding: 0;

    &:hover::after {
      top: 0;
      left: -3px;
    }
  }
}
