/** @format */
@import "../../variables.scss";

.submitted-file {
  .error-header {
    font-size: 1rem;
  }

  .file-link,
  .fa-file-lines {
    color: var(--gray);
  }

  .file-link {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .fa-play,
  .fa-trash-alt,
  .fa-backward {
    margin-left: 0.75rem;
    cursor: pointer;
  }

  .fa-trash-alt {
    margin-right: 0.75rem;
  }

  .file-option-dropdown,
  .file-option-dropdown.show {
    margin-left: 0.5rem;

    /* TODO: probably a cleaner way to disable highlighting*/
    .dropdown-toggle,
    .dropdown-toggle:focus,
    .dropdown-toggle:active {
      &.btn-secondary,
      &.btn-secondary:not(.disabled):active:focus {
        background-color: white;
        color: var(--gray);
        border: none;
        box-shadow: none;
      }
    }
  }

  .validation-error-list {
    width: 65%;
  }

  .mappings-link {
    vertical-align: bottom;
    font-size: 0.9rem;
  }

  .submitted-file-summary {
    font-style: italic;
    text-indent: 1.35rem;
    font-size: 0.75rem;
    font-weight: 600;
    color: #25628f;
  }

  .jde-info {
    color: #25628f;
  }

  &.action-needed {
    .status-display-text {
      color: var(--warning);
    }
  }

  &.failure {
    .status-display-text {
      color: var(--danger);
    }
  }

  &.success {
    .status-display-text {
      color: var(--success);
    }
  }
}

.edit-jdeinfo-form {
  .react-date-picker__wrapper {
    width: 100%;
  }
}

input[type="checkbox"].form-control:focus {
  box-shadow: initial;
}
