/** @format */

.unmapped-account {
  input.form-control[readonly] {
    background-color: white;
    border: none;
    &:focus {
      border: none;
      box-shadow: none;
    }
  }
}

.wtop-accountant-mappings {
  .header-row {
    margin-bottom: 2rem;
  }

  .tab-pane {
    padding: 1rem 2rem;
  }

  .wtop-btn {
    &.download-btn {
      background-color: #28a745;
      border-color: #28a745;
    }
  }

  #accountSearch,
  #entitySearch {
    margin-top: 0.5rem;
  }

  .mapping-set-action-dropdown {
    margin-left: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 5px;

    .dropdown-toggle,
    .dropdown-toggle:focus,
    .dropdown-toggle:active {
      &.btn-secondary,
      &.btn-secondary:not(.disabled):active:focus {
        background-color: white;
        color: #5e5e5e;
        border: none;
        box-shadow: none;
      }
    }
  }
}

.rename-input {
  margin-bottom: 2rem;
}
