/** @format */
@import "../../variables.scss";

.uploadBtn {
  &.btn.btn-primary {
    border-radius: 1rem;
    font-size: 0.75rem;
    padding: 0.5rem 2rem;
    text-transform: uppercase;
  }
}
