/** @format */
.wtop-notification-preferences {
  .column-label-row {
    margin: 1rem auto;
    text-align: center;
    font-weight: 500;
  }

  .preference-toggle-label {
    text-indent: 1rem;
  }

  .operator-preference-container {
    margin-bottom: 1rem;

    &:not(:last-of-type) {
      border-bottom: 1px solid #ddd;
    }
  }

  .requirement-preference-row {
    margin: 0.5rem auto;

    .toggle-switch {
      display: block;
      margin: 0 auto;
    }

    .requirement-name {
      padding-left: 1em;
      text-indent: -1em;
    }
  }
}
