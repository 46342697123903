/** @format */
@use "sass:math";

$phi: 1.6180339887498948482;

// Radius of the white center knob
$slider-knob-radius: 0.75rem;
// How much background is visible around the knob at top, bottom, and one end
$slider-knob-spacing: math.div($slider-knob-radius, 2 * $phi);
// Aspect ratio of the entire slider box
$slider-aspect-ratio: $phi;
// Transition speed when toggling
$slider-transition-time: 0.3s;

.toggle-switch {
  $slider-total-height: ($slider-knob-radius + $slider-knob-spacing) * 2;
  $slider-total-width: $slider-total-height * $slider-aspect-ratio;

  position: relative;
  display: inline-block;
  height: $slider-total-height;
  width: $slider-total-width;
  outline: none;
  margin-bottom: 0;

  input {
    display: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: $slider-transition-time;
    border-radius: $slider-knob-radius + $slider-knob-spacing;

    &:before {
      position: absolute;
      content: "";
      height: $slider-knob-radius * 2;
      width: $slider-knob-radius * 2;
      left: $slider-knob-spacing;
      bottom: $slider-knob-spacing;
      background-color: white;
      transition: $slider-transition-time;
      border-radius: $slider-knob-radius;
    }
  }

  input:checked + .slider {
    background-color: var(--primary);
  }

  input:disabled + .slider {
    cursor: default;
  }
  input:disabled:checked + .slider {
    background-color: var(--primary-disabled);
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    transform: translateX($slider-total-width - $slider-total-height);
  }
}
