/** @format */
.wtop-accountant-operators {
  .header-row {
    margin-bottom: 2rem;

    .wtop-btn {
      float: right;
    }
  }
}
