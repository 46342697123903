/** @format */
.wtop-navbar {
  margin-bottom: 0.75rem;

  .corner-ribbon {
    color: var(--warning);
    font-weight: 600;
    top: 3px;
    left: 67px;
    z-index: 5;
    position: absolute;
    font-size: 75%;
  }
}
