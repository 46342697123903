/** @format */
@import "./variables.scss";

.developerMode .navbar-brand {
  color: var(--warning);
  &:hover {
    color: var(--warning);
  }
}
.oktaToken {
  border: 1px solid lightgray;
  background-color: lightyellow;
  padding: 0.25em;
  border-radius: 0.25em;
  cursor: pointer;
  margin-bottom: 1rem;
}
.oktaToken pre {
  white-space: pre-line;
  height: 5rem;
  overflow: scroll;
}
.oktaToken:hover {
  border: 1px solid darkgray;
}

.react-date-picker__wrapper {
  border: none !important;
}

.react-datepicker__navigation {
  line-height: normal; // replace __reboot's `inherit` to fix alignment of left/right chevrons in react-datepicker
}

.card {
  margin: 0.75rem; /* This might not work globally, but start here and then break out if necessary */
}
.card-group .card {
  margin: 0;
}

.editFormForm {
  padding-top: 1rem;
  border-top: 1px solid #ebe4de;
}

form label {
  display: block;
  margin-bottom: 0.6rem;
}
form label.label-required::after {
  content: " *";
  color: var(--warning);
}
form .requiredNote {
  color: var(--warning);
}

form input.error {
  border-color: var(--danger);
}
form .input-feedback {
  font-size: 14px;
  color: var(--danger);
  margin-top: 0.25rem;

  &.info {
    color: inherit;
  }
}

.editFormButton {
  text-align: right;
}

.rdd-dashboard-requirements {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

#root {
  //background-color: $bg-color;
  min-height: 100vh;
}

.wtop-btn.btn {
  border-radius: 1rem;
  font-size: 0.75rem;
  padding: 0.5rem 2rem;
  text-transform: uppercase;
}

.nav-tabs .nav-item {
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

/* Also used on Home/landing page */
.welltower-logo {
  background-image: url(./logo.png);
  background-repeat: no-repeat;
  background-size: contain;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .welltower-logo {
    background-image: url(./logo@2x.png);
  }
}

/* react-datepicker styles */
// Layer above a Modal
.react-datepicker-popper {
  z-index: 1500;
}
// Make the month picker a little bigger
.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 5rem;
  padding: 1rem 0.5rem;
}
