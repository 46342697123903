/** @format */
.rdd-card {
  &.dropzone--isActive,
  .dropzone--isActive {
    background-color: var(--primary-lightbg);
    border-color: var(--primary);
    border-style: dashed;
  }

  .card-body:not(.rdd-upload-history) {
    min-height: 160px;
  }

  .card-hipaa {
    border-width: 5px;
    border-radius: 1em;
    // Curve the background of RddUploadHistory
    .card-header {
      text-transform: uppercase;
      font-variant: all-small-caps;
    }
    .card-body.rdd-upload-history {
      border-bottom-left-radius: calc(1em - 5px);
      border-bottom-right-radius: calc(1em - 5px);
    }
  }
  .rdd-upload-history {
    background-color: rgba(37, 98, 143, 0.1);

    .header-text {
      font-size: 0.75rem;
      color: #007bff;
    }
    .file-list {
      list-style: none;
      > li::before {
        content: " ";
        display: inline-block;
        border-radius: 0.25rem;
        height: 0.5rem;
        width: 0.5rem;
        background-color: #007bff;
      }
      > li.submittedFile-WARNING::before {
        background-color: var(--warning);
      }
      > li.submittedFile-ERROR::before {
        background-color: var(--danger);
      }
      > li.submittedFile-IN_PROGRESS::before {
        background-color: var(--warning);
      }

      .file-summary-list {
        list-style-type: none;

        li:before {
          content: "-";
          margin-right: 0.25rem;
          margin-left: -1.25rem;
        }
      }
    }
  }

  .operator-upload-btn {
    font-size: 0.75rem;

    .btn {
      font-size: 0.75rem;
      color: #007bff;
    }
  }

  .rdd-lock-icon {
    color: var(--primary);
  }

  .locked-message {
    font-size: 0.75rem;
    // try to have the same height as the other message with text buttons so the card doesn't wiggle on lock/unlock
    margin-top: calc(1px + 0.375rem);
    margin-bottom: calc(1px + 0.375rem);
  }

  .rdd-card-block {
    background: #fff;
    opacity: 0.5;
    min-height: 50px;
    z-index: 50;
    top: 0;
    bottom: 0;
    // Try to size inside the card
    left: 15px;
    right: 15px;
    border-radius: 0.25rem;
    &:has(p) {
      opacity: 0.9;
    }
  }

  .actions-dropdown {
    .dropdown-toggle {
      color: #007bff;
    }

    :after {
      vertical-align: inherit;
    }
  }
}

.rdd-period {
  border-bottom: 1px solid #ddd;

  .btn-link {
    color: #007bff;
    vertical-align: baseline;
  }
  .rdd-period-heading {
    .btn-link {
      padding-bottom: 0;
      display: none;
    }

    &.pointer:hover {
      cursor: pointer;
      .btn-link {
        display: inline-block;
      }
    }
  }
}

.unmapped-account {
  &:not(:last-of-type):after {
    content: "";
    display: block;
    width: 100%;
    border-bottom: 1px solid #ddd;
    margin-top: 1rem;
  }
}
