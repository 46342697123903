/** @format */
#landing {
  .corner-ribbon {
    color: var(--warning);
    font-weight: 600;
    top: -0.5rem;
    left: 2.5rem;
    z-index: 5;
    position: relative;
    font-size: 75%;
  }
  .card {
    border: none;
  }
  /*.screenshot {
    @media (min-width: 1200px) {
      .screenshot {
        background: url("/landing_screenshot.png") no-repeat;
        background-attachment: scroll;
        background-position: center center;
        background-size: contain;
        height: calc(100vh - 5em);
      }
    }
  }*/
}
