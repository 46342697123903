/** @format */
@import "variables";

// Set Bootstrap variables with Welltower colors
$primary: #25628f;
$orange: #f29d38;
$body-bg: #fbfbfb;
//$warning: $orange;

$wt-hover-bg: scale-color($primary, $lightness: 85%);
$wt-active-bg: scale-color($primary, $lightness: 70%);
//dropdown-link-active-bg
$dropdown-link-hover-bg: $wt-hover-bg; // $react-select-hover-bg; // Bootstrap 5 calculates something lighter that's hard to see; this is the Bootstrap 4 color.
$dropdown-bg: white; // Bootstrap 5 calculates something lighter that's hard to see; this is the Bootstrap 4 color.

// Add some more colors that Bootstrap will create as --bs- variables
$colors: (
  "primary-disabled": transparentize($primary, 0.6),
  "primary-lightbg": lighten($primary, 60%),
  // primary-25,50,75 are passed to react-select for theming
  "primary-75": scale-color($primary, $lightness: 25%),
  "primary-50": $wt-active-bg,
  "primary-25": $wt-hover-bg,
);

/*

// I started trying to move our button styles, but that also overlaps with some input padding, dropdownButton, etc.
// TODO - maybe stick with wtop-btn or something to apply Drew's size, uppercase, radius, and padding.
$border-radius: 0.6rem;
$border-radius-lg: 0.8rem;
$border-radius-sm: 0.4rem;

$btn-border-radius: 1rem;
$btn-border-radius-sm: 1rem;

$btn-border-radius-lg: 0.3rem; //1.3rem;
$dropdown-border-radius: 0.3rem;
$btn-padding-y: 0.5rem;
$btn-padding-x: 2rem;
$btn-font-size: 0.75rem;

//$input-btn-padding-y-sm: 0.5rem;
//$input-btn-padding-x-sm: 1rem;
$btn-font-size-sm: 0.75rem;
$btn-font-size-lg: 1rem;

$btn-padding-x-lg: 2rem;
*/

@import "../node_modules/bootstrap/scss/bootstrap";
