/** @format */
@import "../../variables.scss";

// TODO remove these when removing the Classic pickers

$bg: hsl(0, 0%, 100%);
$border: hsl(0, 0%, 80%);

.operator-picker-classic {
  min-width: 10rem;
  border: 1px solid $border;
  border-radius: 0.25rem;

  .favorite-toggle {
    margin-right: 1rem;
  }

  .dropdown-toggle {
    background-color: $bg;
  }
}

.document-type-picker-classic {
  min-width: 10rem;
  border: 1px solid $border;
  margin-left: 0.5rem;
  border-radius: 0.25rem;

  .favorite-toggle {
    margin-right: 1rem;
  }

  .dropdown-toggle {
    background-color: $bg;
    padding-right: 2rem;
  }
}
